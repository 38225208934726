<template>
  <div>
    <div class="col float-right">
      <q-btn-group outline>
        <!-- QR 코드 -->
        <c-qr-btn v-if="editable && educationInfo.documentStatusCd && educationInfo.documentStatusCd !== 'ESC000001'" :mobileUrl="'/sop/edu/result/educationResult?eduEducationId='+param.eduEducationId" ></c-qr-btn>
        <!-- 완료 -->
        <c-btn v-show="editable&&!disabled" :showLoading="false" label="LBLCOMPLETE" icon="check"  @btnClicked="resultComplete" />
        <!-- 교육결과서 -->
        <!-- <c-btn v-show="editable&&disabled" label="LBL0002901" icon="print" @btnClicked="btnResultReport" /> -->
        <!-- 대상자 연명부 -->
        <c-btn v-show="editable&&disabled" label="LBL0002902" icon="print" @btnClicked="btnResultUsers" />
      </q-btn-group>
    </div>
    <c-tab
      type="vertical"
      :height.sync="tabHeight"
      :tabItems="tabItems"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :param="param"
          :planUpdateBtnData="param.planUpdateBtnData"
          :educationInfo.sync="educationInfo"
          @setData="setData"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>

import printJs from 'print-js'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'education-result-tab',
  props: {
    param: {
      type: Object,
      default: () => ({
        eduEducationId: '',
        searchResult: {
          flag: '',
          isSearch: false,
        },
        planUpdateBtnData: {
          title: 'LBLPLAN',
          flag: false,
          research: '',
          planResearch: '',
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      educationInfo: {
        eduEducationId: '',
        educationName: '',
        eduCourseId: '',
        plantCd: '',
        educationCourseName: '',
        educationKindCdLarge: null,
        educationKindCdSmall: null,
        educationTypeCd: null,
        departmentDeptCd: '',
        departmentDeptName: '',
        executionBudget: '',
        estimatedEducationExpenses: '',
        educationMethodName: '',
        educationMethodCd: null,
        educationOverview: '',
        evaluationEvalCd: null,
        documentStatusCd: '',
        qrCodeRoute: '',

        regUserId: '',
        chgUserId: '',
        eduSubjectList: [], // 과정별 교육과목
        delEduSubjectList: [],
        eduAttendeeList: [],
        delEduAttendeeList: [],
        eduEvalUserList: [],
        deleteEduEvalUserList: [],
        
        eduQuestionFlag: 'N',
        eduQuestionMstId: '',
        eduQuestionPassScore: 0,
      },
      serverName: '',
      completeUrl: '',
      editable: true,
      splitter: 5,
      tab: 'educationResultInfo',
      printResultUrl: '',
      printResultUserUrl: '',
      getUrl: '',
    };
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      numHeight = numHeight - 40
      return numHeight + 'px';
    },
    disabled() {
      return this.educationInfo.documentStatusCd === 'ESC000010'
    },
    tabItems() {
      return [
        // 기본정보
        { name: 'educationResultInfo', icon: 'edit', label: '교육평가', component: () => import(`${'./educationResultInfo.vue'}`) },
        // 교육이수
        { name: 'educationComplete', icon: 'how_to_reg', label: '교육이수', component: () => import(`${'./educationComplete.vue'}`) },
      ]
    },
  },
  watch: {
    'param.planUpdateBtnData.research'() {
      this.getDetail();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.edu.result.get.url;
      this.redirectUrl = selectConfig.com.mobileHostname.url;
      this.completeUrl = transactionConfig.sop.edu.result.complete.url;
      this.printResultUrl = selectConfig.sop.edu.result.print.result.url;
      this.printResultUserUrl = selectConfig.sop.edu.result.print.targetUser.url;
      // list setting
      this.serverUrl();
      this.getDetail();
    },
    getDetail() {
      if (this.param.eduEducationId) {
        this.$http.url = this.$format(this.getUrl, this.param.eduEducationId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.educationInfo = _result.data;
        },);
      }
    },
    print() {
      printJs({
        printable: 'printJS-form',
        type: 'html',
        font_size: '20px',
        targetStyles: ['*'],
      })
    },
    serverUrl() {
      this.$http.url = this.redirectUrl;
      this.$http.type = 'GET';
      this.$http.param = {};
      this.$http.request(
        _result => {
          this.serverName = _result.data
        },
        _error => {
          window.getApp.$emit('APP_REQUEST_ERROR', _error);
        }
      );
    },
    setData(data) {
      this.param.eduEducationId = data;
      this.getDetail();
    },
    resultComplete() {
      let flag = false;
      if (!flag) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSG0000868', // 결과완료하시겠습니까?(저장과 함께 처리됩니다.)
          // TODO : 점검완료 하시겠습니까?
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.educationInfo.documentStatusCd = 'ESC000010'
            this.educationInfo.regUserId = this.$store.getters.user.userId
            this.educationInfo.chgUserId = this.$store.getters.user.userId
            this.$http.url = this.completeUrl;
            this.$http.type = 'PUT';
            this.$http.param = this.educationInfo;
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
      });
      }
    },
    btnResultReport() {
      let thisVue = this;
      this.$http.url = this.printResultUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        eduEducationId: this.param.eduEducationId
      }
      this.$http.request(
        _result => {
          let fileOrgNm = this.educationInfo.educationName + ' 결과서.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
    btnResultUsers() {
      let thisVue = this;
      this.$http.url = this.printResultUserUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        eduEducationId: this.param.eduEducationId
      }
      this.$http.request(
        _result => {
          let fileOrgNm = this.educationInfo.educationName + ' 연명부.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
  }
};
</script>
